import {mapGetters} from "vuex";

import 'core-js/actual'
import _ from "underscore";
import groupObjMonitoring from "@/helpers/groupObjMonitoring";


const user = JSON.parse(localStorage.getItem('user'))
export default {

    computed: {
        ...mapGetters('users', ['users']),
        ...mapGetters('serviceMonitoringGroup', ['services']),
    },
    methods:{
        sort(){
             this.sorted = Object.keys(this.sorted)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = this.sorted[key];
                    return accumulator;
                }, {});
        },
        loadAddition(ownerId) {
            const servicesPayload = {
                order_by: "released_at",
                order_type: "desc"
            };
            if(ownerId !== user.id) {
                servicesPayload['filters'] = {
                    user_id: ownerId
                };
            }
            Promise.all([
                this.$store.dispatch('users/getUsersList', {
                    filters: {
                        parent_or_self: ownerId
                    }
                }),
                this.$store.dispatch('serviceMonitoringGroup/getServices', servicesPayload)
            ]).finally(() => {
                this.sorted = this.services.group(groupObjMonitoring);
                this.sort();
                this.isLoading = false;
            })
        },
        loadAdditionCreate() {
            const user = JSON.parse(localStorage.getItem('user'));
            Promise.all([
                this.$store.dispatch('users/getUsersList', {
                    filters: {
                        parent_or_self: user.id
                    },
                    limit: this.usersLimit,
                    offset: this.offset
                }),
                this.$store.dispatch('serviceMonitoringGroup/getServices', {
                    order_by: "released_at",
                    order_type: "desc"
                })
            ]).finally(() => {
                this.sorted = this.services.group(groupObjMonitoring);
                this.sort();
                this.isLoading = false;
                this.usersLoading = false;
            })
        }
    },
    mounted() {
        this.$store.dispatch('serviceMonitoringGroup/clearObjectDetail');
        this.isLoading = true;
        this.$store.dispatch('users/clearUsersList');
        this.$store.dispatch('serviceMonitoringGroup/clearServices');
        if(this.payloadProcess === 'create') {
            this.loadAdditionCreate();
        }
    },
    watch: {
        detailedServicesGroup: {
            deep: true,
            handler(val) {
                if(val.hasOwnProperty('owner') && !this.services.length && !this.users.length) {
                    this.loadAddition(val.owner.id)
                }
            }
        }
    }
}